<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>

                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>HQ</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link" @responseLoaded="getSettings(slotProps.links)">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <hq-navigation :links="slotProps.links"></hq-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <HqSettingsPanel ref="hqSettingsDetails" :details="settings" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails(slotProps.links)" @hideTable="toggleHideTable"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HqNavigation from "@/v3/components/navigation/HqNavigation.vue";
import HqSettingsPanel from "@/v3/pages/HqSettingsPanel.vue";

export default {
    inject: ["$validator"],
    components: {
        HqSettingsPanel,
        HqNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            hideTable: false,
            settings: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },


    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getSettings(links){
            this.loading = true;
            let link = links.filter(link => link.rels.includes('dashboards')).first();
            if(link) {
                Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                Siren.get(link.href)
            .then(res => {
                    this.settings = res.body;
                    this.loading = false;
                });
            }
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.hqSettingsDetails.editing = false;
            this.$refs.hqSettingsDetails.tabOpen = 'settings';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(links){
            this.getSettings(links);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.details = null;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_light"
        }

    }
}
</script>

